import styled, { css } from 'styled-components';
import { rem } from 'polished';
import ImportedHeading from 'src/components/Heading';
import { up } from 'styled-breakpoints';
import _Button from 'src/components/Button';
import { breakpointsKeys } from 'src/enums/breakpoints';
import { ShowMoreHeadingLink as _ShowMoreHeadingLink } from './ShowMoreHeadingLink';
import { WrapperWithHeadingAndLink } from './WrapperWithHeadingAndLink';

export const Content = styled.div`
  ${({ shouldBeSticky }) => {
    if (!shouldBeSticky) {
      return css`
        position: static !important;
      `;
    }

    return css``;
  }};
`;

const createStylesWithBackground = () => css`
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[9]};
  border-radius: 20px;
  padding: ${rem(10)};
  & > span {
    background-color: ${({ theme }) => theme.defaultPalette.monochrome[9]};
  }
`;

const createStylesForBorder = () => css`
  border-radius: 20px;
  padding: ${rem(10)};

  border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
`;

const stylesForHeadingWithLink = () => css`
  overflow: hidden;
  > span {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${up(breakpointsKeys.TABLET)} {
      display: unset;
      position: unset;
    }
  }
`;

export const HeadingAside = styled(ImportedHeading)`
  margin-bottom: 0;
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
  & > span {
    background-color: transparent;
  }
`;

export const Heading = styled(ImportedHeading)`
font-size: ${({ theme }) => theme.typography.fontSize.m2};
padding: 2px;
&:after {
    content: '';
    z-index: -1;
    background: ${({ theme }) => theme.heading.quaternary.decorBg};
    position: absolute;
    height: ${rem(1)};
    width: calc(100% - ${rem(20)});
    top: 50%;
    left: ${rem(10)};
  }

${({ CMSSpacing }) => CMSSpacing?.['margin-top'] && `margin-top: ${CMSSpacing['margin-top']}px`}
  
  margin-bottom: ${({ CMSSpacing }) => (CMSSpacing?.['margin-bottom'] ? `${CMSSpacing?.['margin-bottom']}px` : '0')};
  
  ${({ displayBackground }) => displayBackground && createStylesWithBackground()}
  ${({ displayBorder }) => displayBorder && createStylesForBorder()}
  
  ${({ isLinkRendered }) => isLinkRendered && stylesForHeadingWithLink()}
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }

`;

export const StandaloneHeading = styled(ImportedHeading)`
  font-size: ${({ theme, isBig }) => (isBig ? theme.typography.fontSize.l1 : theme.typography.fontSize.m2)};
  font-family: ${({ theme, isBig }) => (isBig ? theme.global.fontFamily.primary : theme.global.fontFamily.secondary)};

  &::before {
    content: '';
    z-index: -1;
    background: ${({ theme }) => theme.heading.quaternary.decorBg};
    position: absolute;
    height: ${rem(1)};
    width: calc(100% - ${rem(20)});
    top: 50%;

    left: ${rem(10)};
  }
  > span {
    width: fit-content;
    margin: auto;
  }

  ${({ displayBackground }) => displayBackground && createStylesWithBackground()}
  ${({ displayBorder }) => displayBorder && createStylesForBorder()}

  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
  ${({ isLinkRendered }) => isLinkRendered && stylesForHeadingWithLink()}
  
  margin-bottom: ${({ CMSSpacing }) => (CMSSpacing?.['margin-bottom'] ? `${CMSSpacing?.['margin-bottom']}px` : '0')};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-top'] && `margin-top: ${CMSSpacing['margin-top']}px;`}
  
`;

export const HeadingShowMoreButton = styled(_Button)`
  .buttonIcon {
    margin-left: ${rem(4)};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    &:hover {
      .buttonIcon {
        > path {
          ${({ theme }) => theme.helpers.transition(['fill', 'stroke'])};
          fill: ${({ theme }) => theme.defaultPalette.teal[0]};
          stroke: ${({ theme }) => theme.defaultPalette.monochrome[0]};
        }
      }
    }
  }
`;

const carouselWrapperDisplayBackgroundStyles = () => css`
  margin-left: ${rem(-16)};
  margin-right: ${rem(-16)};
  padding: ${rem(12)} ${rem(20)};
  & .productTileWrapper {
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  }
  .swiper-slide {
    ${up(breakpointsKeys.DESKTOP)} {
      transition: box-shadow 0.3s;
      &:hover {
        box-shadow: 7px 8px 27px 0px rgba(0, 0, 0, 0.1);
      }
      margin-bottom: 27px;
    }
  }

  ${up(breakpointsKeys.TABLET)} {
    margin-left: unset;
    margin-right: unset;
    border-radius: ${rem(30)};
    padding: ${rem(12)} ${rem(10)} ${rem(20)};
  }
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[9]};
  ${up(breakpointsKeys.DESKTOP)} {
    padding: ${rem(12)} 0 ${rem(5)};
  }
`;
const carouselWrapperDisplayBorderStyles = () => css`
  margin-left: ${rem(-16)};
  margin-right: ${rem(-16)};
  padding: ${rem(12)} ${rem(20)};
  border-top: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  border-bottom: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  & .productTileWrapper {
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  }
  .swiper-slide {
    ${up(breakpointsKeys.DESKTOP)} {
      transition: box-shadow 0.3s;
      &:hover {
        box-shadow: 7px 8px 27px 0px rgba(0, 0, 0, 0.1);
      }
      margin-bottom: 27px;
    }
  }

  ${up(breakpointsKeys.TABLET)} {
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
    margin-left: unset;
    margin-right: unset;

    border-radius: ${rem(30)};
    padding: ${rem(12)} ${rem(10)} ${rem(20)};
  }

  ${up(breakpointsKeys.DESKTOP)} {
    padding: ${rem(12)} 0 ${rem(5)};
  }
`;

export const CarouselWrapper = styled.div`
  ${({ displayBackground }) => displayBackground && carouselWrapperDisplayBackgroundStyles()};
  ${({ displayBorder }) => displayBorder && carouselWrapperDisplayBorderStyles()};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-top'] && `margin-top: ${CMSSpacing['margin-top']}px;`};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-bottom'] && `margin-bottom: ${CMSSpacing['margin-bottom']}px;`};
`;

const articlesWrapperDisplayBackgroundStyles = () => css`
  margin-left: ${rem(-16)};
  margin-right: ${rem(-16)};
  padding: ${rem(12)} ${rem(20)};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[9]};
  & .productTileWrapper {
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  }
  .swiper-slide {
    ${up(breakpointsKeys.DESKTOP)} {
      transition: box-shadow 0.3s;
      &:hover {
        box-shadow: 7px 8px 27px 0px rgba(0, 0, 0, 0.1);
      }
      margin-bottom: 27px;
    }
  }

  ${up(breakpointsKeys.TABLET)} {
    margin-left: unset;
    margin-right: unset;
    border-radius: ${rem(30)};
    padding: ${rem(12)};
  }
`;

const articlesWrapperDisplayBorderStyles = () => css`
  margin-left: ${rem(-16)};
  margin-right: ${rem(-16)};

  padding: ${rem(12)} ${rem(20)};

  border-top: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  border-bottom: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  & .productTileWrapper {
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  }
  .swiper-slide {
    ${up(breakpointsKeys.DESKTOP)} {
      transition: box-shadow 0.3s;
      &:hover {
        box-shadow: 7px 8px 27px 0px rgba(0, 0, 0, 0.1);
      }
      margin-bottom: 27px;
    }
  }

  ${up(breakpointsKeys.TABLET)} {
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
    margin-left: unset;
    margin-right: unset;

    border-radius: ${rem(30)};
    padding: ${rem(12)};
  }
`;

export const ArticlesWrapper = styled.div`
  ${({ displayBackground }) => displayBackground && articlesWrapperDisplayBackgroundStyles()};
  ${({ displayBorder }) => displayBorder && articlesWrapperDisplayBorderStyles()};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-top'] && `margin-top: ${CMSSpacing['margin-top']}px;`};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-bottom'] && `margin-bottom: ${CMSSpacing['margin-bottom']}px;`};
`;

export const ShowMoreHeadingLink = styled(_ShowMoreHeadingLink)`
  margin-top: ${rem(5)};
  ${up(breakpointsKeys.TABLET)} {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-right: ${rem(12)};
  }
`;

export const ShowMoreHeadingLinkCarouselUpperMobile = styled(ShowMoreHeadingLink)`
  ${up(breakpointsKeys.TABLET)} {
    margin-right: 0;
    ${({ isBig }) => (!isBig ? 'top: 0; transform: translateY(0)' : 'top: 0; transform: translateY(15%)')}
  }
`;

export const ShowMoreHeadingLinkCarousel = styled(_ShowMoreHeadingLink)`
  margin-top: ${rem(10)};
  width: 100%;
  & > span {
    margin: auto;
  }
`;
export const ShowMoreHeadingLinkHtmlFromSet = styled(_ShowMoreHeadingLink)`
  margin-top: ${rem(20)};
  width: 100%;
  & > span {
    margin: auto;
  }
`;

export const CarouselHeading = styled(Heading)`
  margin-bottom: 0;
  padding: 0 0 ${rem(10)};
  font-family: ${({ theme }) => theme.global.fontFamily.secondary};
  > span {
    background-color: transparent;
  }

  ${up(breakpointsKeys.TABLET)} {
    padding: 0 0 ${rem(20)};
  }
`;

export const HeadingForHeadingWithLinkWrapper = styled(Heading)`
  margin-bottom: 0;
  padding: 0 0 ${rem(10)};
  font-size: ${({ theme, isBig }) => (isBig ? theme.typography.fontSize.l1 : theme.typography.fontSize.m2)};
  font-family: ${({ theme, isBig }) => (isBig ? theme.global.fontFamily.primary : theme.global.fontFamily.secondary)};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-top'] && `margin-top: ${CMSSpacing['margin-top']}px`};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-bottom'] && `margin-bottom: ${CMSSpacing['margin-bottom']}px`};
  > span {
    background-color: transparent;
  }

  ${up(breakpointsKeys.TABLET)} {
    padding: 0 0 ${rem(20)};
  }
`;

export const HtmlFromSetHeading = styled(Heading)`
  margin-bottom: 0;
  font-size: ${({ theme, isBig }) => (isBig ? theme.typography.fontSize.l1 : theme.typography.fontSize.m2)};
  font-family: ${({ theme, isBig }) => (isBig ? theme.global.fontFamily.primary : theme.global.fontFamily.secondary)};
  padding: 0 0 ${rem(15)};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-top'] && `margin-top: ${CMSSpacing['margin-top']}px`};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-bottom'] && `margin-bottom: ${CMSSpacing['margin-bottom']}px`};
  ${up(breakpointsKeys.TABLET)} {
    padding: 0 0 ${rem(12)};
  }
`;

const wrapperWithBackgroundStyles = () => css`
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[9]};
  margin-left: ${rem(-16)};
  margin-right: ${rem(-16)};
  padding: ${rem(10)} ${rem(15)};

  ${up(breakpointsKeys.TABLET)} {
    border-radius: ${rem(30)};
    margin-left: unset;
    margin-right: unset;
    padding: ${rem(10)};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    padding: ${rem(12)};
  }
`;

const wrapperWithBorderStyles = () => css`
  border-top: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  border-bottom: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  margin-left: ${rem(-16)};
  margin-right: ${rem(-16)};
  padding: ${rem(10)} ${rem(15)};

  ${up(breakpointsKeys.TABLET)} {
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
    border-radius: ${rem(30)};
    margin-left: unset;
    margin-right: unset;
    padding: ${rem(10)};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    padding: ${rem(12)};
  }
`;
const wrapperWithBackgroundStylesForProducersCarousel = () => css`
  /* margin: auto ${rem(-16)}; */
  /* margin: ${rem(24)} auto; */
  /* padding: ${rem(12)} ${rem(16)}; */
  padding: ${rem(12)} 0;
  margin-left: ${rem(-16)};
  margin-right: ${rem(-16)};

  background-color: ${({ theme }) => theme.defaultPalette.monochrome[9]};
  ${up(breakpointsKeys.TABLET)} {
    
    border-radius: ${rem(30)};
    /* margin: auto; */
    /* margin: ${rem(30)} auto; */
    margin-left: unset;
    margin-right: unset;
    padding: ${rem(12)}
  }
  
`;

const wrapperWithBorderStylesForProducersCarousel = () => css`
   padding: ${rem(12)} 0;
  margin-left: ${rem(-16)};
  margin-right: ${rem(-16)};
  border-top: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  border-bottom: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]}; 
  /* margin: ${rem(24)} auto; */
  ${up(breakpointsKeys.TABLET)} {
    
    border-top: unset;
    border-bottom: unset;
    margin-left: unset;
    margin-right: unset;
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
    border-radius: ${rem(30)};
    /* margin: ${rem(30)} auto; */
    /* padding: ${rem(4)} 0; */
    padding: ${rem(12)}
  }
 
`;

export const WrapperWithBorder = styled.div`
  ${({ displayBackground }) => displayBackground && wrapperWithBackgroundStyles()}
  ${({ displayBorder }) => displayBorder && wrapperWithBorderStyles()}
  ${({ CMSSpacing }) => CMSSpacing?.['margin-top'] && `margin-top: ${CMSSpacing['margin-top']}px`};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-bottom'] && `margin-bottom: ${CMSSpacing['margin-bottom']}px`};
 
  
  
`;
export const ProducersCarouselWrapperWithBorder = styled.div`
  
  ${({ displayBackground }) => displayBackground && wrapperWithBackgroundStylesForProducersCarousel()}
  ${({ displayBorder }) => displayBorder && wrapperWithBorderStylesForProducersCarousel()}
  ${({ CMSSpacing }) => CMSSpacing?.['margin-top'] && `margin-top: ${CMSSpacing['margin-top']}px`};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-bottom'] && `margin-bottom: ${CMSSpacing['margin-bottom']}px`};
`;

const wrapperWithBackgroundStylesForHtmlFromSet = () => css`
margin-left: ${rem(-16)};
margin-right: ${rem(-16)};
padding: ${rem(12)} ${rem(16)};

background-color: ${({ theme }) => theme.defaultPalette.monochrome[9]};
${up(breakpointsKeys.TABLET)} {
  border-radius: ${rem(30)};
  margin-left: unset;
  margin-right: unset;
  
  padding: ${rem(12)};
}
${up(breakpointsKeys.DESKTOP)} {
  /* padding: ${rem(12)} 0; */
}
`;
const wrapperWithBorderStylesForHtmlFromSet = () => css`
margin-left: ${rem(-16)};
margin-right: ${rem(-16)};
padding: ${rem(12)} ${rem(16)};
border-top: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
border-bottom: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};


${up(breakpointsKeys.TABLET)} {
  border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  margin-left: unset;
  margin-right: unset;
  border-radius: ${rem(30)};
  padding: ${rem(12)};
  /* padding: ${rem(12)} ${rem(16)}; */
}
${up(breakpointsKeys.DESKTOP)} {
  /* padding: ${rem(12)} 0; */
}
`;

export const ShowMoreHeadingLinkHtmlSetUpperMobile = styled(ShowMoreHeadingLink)`
  ${up(breakpointsKeys.TABLET)} {
    margin-right: ${rem(5)};
    ${({ isBig }) => (!isBig ? 'top: 0; transform: translateY(0)' : 'top: 0; transform: translateY(15px)')}
  }
`;

export const WrapperWithBorderForHtmlFromSet = styled.div`

  
  ${({ displayBackground }) => displayBackground && wrapperWithBackgroundStylesForHtmlFromSet()}
  ${({ displayBorder }) => displayBorder && wrapperWithBorderStylesForHtmlFromSet()}
  ${({ CMSSpacing }) => CMSSpacing?.['margin-top'] && `margin-top: ${CMSSpacing['margin-top']}px !important`};
  ${({ CMSSpacing }) => CMSSpacing?.['margin-bottom'] && `margin-bottom: ${CMSSpacing['margin-bottom']}px !important`};
  

  
`;

export const WrapperWithHeadingAndLinkForProductsCarousel = styled(WrapperWithHeadingAndLink)`
  ${up(breakpointsKeys.TABLET)} {
    margin-right: ${rem(12)};
  }
`;
