import { query } from 'config/queries';
import { indexes } from 'config/filters';

export const removeUnnecessaryQueryStrings = (str) => {
  const queries = new RegExp(`[?&]${query}=`, 'gmi');

  return str.replace(queries, '');
};

export const formatIndexesQuery = (indexesListStr) =>
  `${indexes}:${indexesListStr
    .split(',')
    .map((i) => i.trim())
    .join(',')}`;
