import React from 'react';

import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import * as S from './styles';
import transformUrlToNextLink from 'src/utils/transformUrlToNextLink';
import { breakpointsKeys } from 'src/enums/breakpoints';
import { defaultLanguage } from 'config/locales';

export const WrapperWithHeadingAndLink = ({
  children,
  heading = {},
  routingMap = {},
  locale = defaultLanguage,
  className,
}) => {
  const currentBreakpoint = useBreakpointDetector();

  const isMobile = currentBreakpoint === breakpointsKeys.MOBILE;
  const renderHeading = !!heading;
  const routesMap = new Map(routingMap?.routesMap);
  const slugsMap = new Map(routingMap?.slugsMap);
  const addLangToLink = () => {
    if (!heading?.link) return null;
    if (locale !== defaultLanguage) {
      return `${locale}${heading.link}`;
    }
    return heading.link;
  };

  const hrefWithLang = addLangToLink();

  const nextLinkData = transformUrlToNextLink({ href: hrefWithLang, routesMap, slugsMap });

  const CMSSpacing = {
    'margin-top': heading?.marginTop || 0,
    'margin-bottom': heading?.marginBottom || 0,
  };

  return (
    <>
      {(heading?.firstLine || !isMobile) && renderHeading && (
        <S.HeadingForHeadingWithLinkWrapper
          variant="quaternary"
          component={heading?.tag}
          isLinkRendered={!!nextLinkData}
          displayBorder={false}
          CMSSpacing={CMSSpacing}
          isBig={heading?.isBig}
        >
          {heading?.firstLine}
          {!!nextLinkData && !isMobile && (
            <S.ShowMoreHeadingLinkCarouselUpperMobile
              nextLinkData={nextLinkData}
              className={className}
              isBig={heading?.isBig}
            />
          )}
        </S.HeadingForHeadingWithLinkWrapper>
      )}
      {children}

      {!!nextLinkData && isMobile && (
        <S.ShowMoreHeadingLinkCarousel nextLinkData={nextLinkData} className={className} />
      )}
    </>
  );
};
