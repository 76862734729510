import * as S from './styles';

export const ProducersCarouselWrapperWithBorder = ({
  children,
  displayBackground = false,
  displayBorder = false,
  CMSSpacing = {},
}) => {
  return (
    <S.ProducersCarouselWrapperWithBorder
      displayBackground={displayBackground}
      displayBorder={displayBorder}
      CMSSpacing={CMSSpacing}
    >
      {children}
    </S.ProducersCarouselWrapperWithBorder>
  );
};
