import * as S from './styles';

export const WrapperWithBorder = ({
  children,
  displayBackground = false,
  displayBorder = false,
  CMSSpacing = {},
  className,
  ...rest
}) => {
  return (
    <S.WrapperWithBorder
      displayBackground={displayBackground}
      displayBorder={displayBorder}
      CMSSpacing={CMSSpacing}
      className={className}
      {...rest}
    >
      {children}
    </S.WrapperWithBorder>
  );
};
