import { defaultLanguage } from 'config/locales';

import { breakpointsKeys } from 'src/enums/breakpoints';
import transformUrlToNextLink from 'src/utils/transformUrlToNextLink';

import useBreakpointDetector from 'src/hooks/useBreakpointDetector';

import * as S from './styles';

export const WrapperWithBorderForHtmlFromSet = ({
  children,
  displayBackground = false,
  displayBorder = false,
  heading = {},
  routingMap = {},
  locale,
  CMSSpacing = {},
  shouldRenderInSideColumns = false,
}) => {
  const currentBreakpoint = useBreakpointDetector();
  const isMobile = currentBreakpoint === breakpointsKeys.MOBILE;
  const isTablet = currentBreakpoint === breakpointsKeys.TABLET;
  const routesMap = new Map(routingMap?.routesMap);
  const slugsMap = new Map(routingMap?.slugsMap);
  const addLangToLink = () => {
    if (!heading?.link) return null;
    if (locale !== defaultLanguage) {
      return `${locale}${heading.link}`;
    }
    return heading.link;
  };

  const nextLinkData = transformUrlToNextLink({ href: addLangToLink(), routesMap, slugsMap });
  const CMSSpacingForHeading = {
    'margin-top': heading?.marginTop || 0,
    'margin-bottom': heading?.marginBottom || 0,
  };

  return (
    <S.WrapperWithBorderForHtmlFromSet
      displayBackground={displayBackground}
      displayBorder={displayBorder}
      CMSSpacing={CMSSpacing}
    >
      {heading?.firstLine && !!heading && (
        <S.HtmlFromSetHeading
          variant="quaternary"
          component={heading?.tag}
          CMSSpacing={CMSSpacingForHeading}
          isLinkRendered={!!nextLinkData}
          displayBackground={displayBackground}
          displayBorder={heading?.displayBorder}
          isBig={heading?.isBig}
        >
          {heading?.firstLine}

          {!!nextLinkData && Boolean(!shouldRenderInSideColumns && !isMobile) && (
            <S.ShowMoreHeadingLinkHtmlSetUpperMobile nextLinkData={nextLinkData} isBig={heading?.isBig} />
          )}
        </S.HtmlFromSetHeading>
      )}
      {children}
      {!!nextLinkData && !isTablet && Boolean(isMobile || shouldRenderInSideColumns) && (
        <S.ShowMoreHeadingLinkHtmlFromSet nextLinkData={nextLinkData} />
      )}
    </S.WrapperWithBorderForHtmlFromSet>
  );
};
