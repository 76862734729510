import { uiComponentVariant } from 'src/enums/uiComponentVariant';

import useTranslation from 'src/hooks/useTranslation';

import ArrowInCircle from 'src/components/Icons/ArrowInCircle';
import Link from 'src/components/Link';

import * as S from './styles';

export const ShowMoreHeadingLink = ({ nextLinkData = {}, className }) => {
  const { t } = useTranslation();

  return (
    <Link
      variant={uiComponentVariant.SECONDARY}
      isNextLink
      href={`/${nextLinkData.layout}`}
      nextLinkProps={{ as: nextLinkData.slug }}
      className={className}
    >
      <S.HeadingShowMoreButton variant={uiComponentVariant.NONARY} isRounded>
        {t('lookForMoreLabel')} <ArrowInCircle className="buttonIcon" />
      </S.HeadingShowMoreButton>
    </Link>
  );
};
