export const articlesListVariants = {
  0: 'with img and desc',
  1: 'title and date',
  2: 'title only',
  3: 'title date and desc only',
  4: 'title desc date with slider on mobile',
  'with img and desc': 0,
  'title and date': 1,
  'title only': 2,
  'title date and desc only': 3,
  'title desc date with slider on mobile': 4,
};
