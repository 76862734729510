// TODO: Think about name https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/display-name.md
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { Fragment } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { defaultLanguage } from 'config/locales';
import { markdownToHtml } from 'src/utils/markdownToHtml';
import { mapDatoResponseToProducersQueryString } from 'src/utils/mapDatoResponseToProducersQueryString';

import { mapDatoResponseToArticleTagsArray } from 'src/utils/mapDatoResponseToArticleTagsArray';
import { mapDatoResponseToArticleCategoriesArray } from 'src/utils/mapDatoResponseToArticleCategoriesArray';
import { removeUnnecessaryQueryStrings, formatIndexesQuery } from 'src/utils/offerCarouselQueryFormatter';
import transformUrlToNextLink from 'src/utils/transformUrlToNextLink';

import { filterNavigationSet } from 'src/utils/filterNavigationSet';
import { articleListSizes } from 'src/enums/articleListSizes';
import { articlesListVariants } from 'src/enums/articlesListVariants';
import { WrapperWithBorder } from './WrapperWithBorder';
import { ProducersCarouselWrapperWithBorder } from './ProducersCarouselWrapperWithBorder';
import { WrapperWithBorderForHtmlFromSet } from './WrapperWithBorderForHtmlFromSet';
import { WrapperWithHeadingAndLink } from './WrapperWithHeadingAndLink';
import { WrapperWithHeadingAndLinkAside } from './WrapperWithHeadingAndLinkAside';

import ProducersList from 'src/components/ProducersList';
import Spacer from 'src/components/Spacer';

import * as S from './styles';

const ImagesCarousel = dynamic(() => import('../ImagesCarousel'));
const ImagesGallery = dynamic(() => import('../ImagesGallery'));
const ArticlesCarousel = dynamic(() => import('../ArticlesCarousel'));
const GridNavigation = dynamic(() => import('../GridNavigation'));
const BannersCarousel = dynamic(() => import('../BannersCarousel'));
const BannersSlider = dynamic(() => import('../BannersSlider'));
const ArticlesList = dynamic(() => import('../ArticlesList'));
const BranchInfo = dynamic(() => import('../BranchInfo'));
const ColumnNavigation = dynamic(() => import('../ColumnNavigation'));
const BrandShowcase = dynamic(() => import('../BrandShowcase'));
const ExternalScript = dynamic(() => import('../ExternalScript'));
const ShortUserSummary = dynamic(() => import('../ShortUserSummary'));
const CarouselOfProductsFromQuery = dynamic(() => import('../CarouselOfProductsFromQuery'));
const MarketingAgrees = dynamic(() => import('../MarketingAgrees'));
const EInvoice = dynamic(() => import('../EInvoice'));
const RecommendationHorizontalList = dynamic(() => import('../RecommendationHorizontalList'));
const RegulationsAcceptance = dynamic(() => import('../RegulationsAcceptance'));
const Promotion = dynamic(() => import('../Promotion'));

const elementHandlers = {
  /* eslint-disable-next-line react/prop-types */
  markdown: ({ markdown, displayBackground, displayBorder, locale, routingMap, heading }) => {
    if (!markdown) return null;
    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder}>
        <WrapperWithHeadingAndLink heading={heading} locale={locale} routingMap={routingMap}>
          <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: markdownToHtml(markdown) }} />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },
  /* eslint-disable-next-line react/prop-types */
  html: ({ html, CMSSpacing, displayBackground, displayBorder, heading, locale, routingMap, where }) => {
    const shouldRenderInSideColumns = where === 'left' || where === 'right';

    const Wrapper = shouldRenderInSideColumns ? WrapperWithHeadingAndLinkAside : WrapperWithHeadingAndLink;
    if (!html) return null;
    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <Wrapper heading={heading} locale={locale} routingMap={routingMap}>
          <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: html }} />
        </Wrapper>
      </WrapperWithBorder>
    );
  },
  /* eslint-disable-next-line react/prop-types */
  html_from_set: ({ htmlSet, CMSSpacing, displayBackground, displayBorder, heading, routingMap, locale, where }) => {
    const shouldRenderInSideColumns = where === 'left' || where === 'right';
    if (!htmlSet.html && !htmlSet.htmlNotTranslated) return null;
    return (
      <WrapperWithBorderForHtmlFromSet
        displayBackground={displayBackground}
        displayBorder={displayBorder}
        heading={heading}
        routingMap={routingMap}
        locale={locale}
        CMSSpacing={CMSSpacing}
        shouldRenderInSideColumns={shouldRenderInSideColumns}
      >
        <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: htmlSet.html }} />
        <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: htmlSet.htmlNotTranslated }} />
      </WrapperWithBorderForHtmlFromSet>
    );
  },
  /* eslint-disable-next-line react/prop-types */
  script_from_set: ({ scriptSet }) => {
    /* eslint-disable-next-line */
    const depsStr = scriptSet.dependencies.trim() || '';
    if (!scriptSet) return null;
    return (
      <ExternalScript
        /* eslint-disable-next-line */
        script={scriptSet.script}
        /* eslint-disable-next-line */
        deps={!depsStr ? [] : depsStr.split(',').map((src) => src.trim())}
        /* eslint-disable-next-line */
        pageExitScript={scriptSet.pageExitScript || ''}
        scriptNotTranslated={scriptSet.scriptNotTranslated}
      />
    );
  },
  /* eslint-disable-next-line react/prop-types */
  spacer: ({ isLarge }) => <Spacer large={isLarge} />,
  /* eslint-disable-next-line react/prop-types */
  heading: ({ firstLine, tag, CMSSpacing, displayBackground, displayBorder, link, locale, routingMap, isBig }) => {
    const routesMap = new Map(routingMap?.routesMap);
    const slugsMap = new Map(routingMap?.slugsMap);
    const addLangToLink = () => {
      if (!link) return null;
      if (locale !== defaultLanguage) {
        return `${locale}${link}`;
      }
      return link;
    };

    const nextLinkData = transformUrlToNextLink({ href: addLangToLink(), routesMap, slugsMap });
    if (!firstLine) return null;
    return (
      <S.StandaloneHeading
        variant="quaternary"
        component={tag}
        CMSSpacing={CMSSpacing}
        displayBackground={displayBackground}
        displayBorder={displayBorder}
        isLinkRendered={!!nextLinkData}
        isBig={isBig}
      >
        {firstLine}
        {!!nextLinkData && <S.ShowMoreHeadingLink nextLinkData={nextLinkData} />}
      </S.StandaloneHeading>
    );
  },
  heading_aside: ({ firstLine, tag, CMSSpacing, displayBackground, displayBorder }) => {
    if (!firstLine) return null;
    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <S.HeadingAside variant="quaternary" component={tag} fontStyle="secondary" fontSizeIndex={3}>
          {firstLine}
        </S.HeadingAside>
      </WrapperWithBorder>
    );
  },
  /* eslint-disable-next-line react/prop-types */
  offer_carousel: ({
    indexes,
    query,
    shuffle,
    limit,
    hideOnntopMarkers,
    coreRoutesInfo,
    locale,
    user,
    CMSSpacing,
    heading,
    displayBackground = false,
    routingMap,
    displayBorder,
  }) => {
    const defaultNumberOfElements = 5;

    return (
      <S.CarouselWrapper displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <S.WrapperWithHeadingAndLinkForProductsCarousel heading={heading} locale={locale} routingMap={routingMap}>
          {query?.length || indexes?.length ? (
            <CarouselOfProductsFromQuery
              query={indexes?.length ? formatIndexesQuery(indexes) : removeUnnecessaryQueryStrings(query)}
              shuffle={shuffle}
              /* eslint-disable-next-line react/prop-types */
              productPageSlug={coreRoutesInfo.productPage.name[locale]}
              /* eslint-disable-next-line react/prop-types */
              productPageLayout={coreRoutesInfo.productPage.layout}
              onnTopHomePageLayout={coreRoutesInfo.onntopHomepage.layout}
              onnTopHomePageSlug={coreRoutesInfo.onntopHomepage.name[locale]}
              userName={user?.user?.name || null}
              userEmail={user?.user?.email || null}
              hideOnnTop={hideOnntopMarkers}
              limit={limit || defaultNumberOfElements}
            />
          ) : null}
        </S.WrapperWithHeadingAndLinkForProductsCarousel>
      </S.CarouselWrapper>
    );
  },
  /* eslint-disable-next-line react/prop-types */
  custom_carousel: ({ slidesPerPage, infinite, autoplay, customCarouselSet }) => {
    return (
      <ImagesCarousel
        slidesPerPage={+slidesPerPage}
        infinite={infinite}
        autoPlay={autoplay}
        /* eslint-disable-next-line react/prop-types */
        slides={customCarouselSet.customCarouselSetContent.map((entry) => {
          const { title, image, page } = entry;

          return {
            imgUrl: image.url,
            slug: page?.slug ? `/${page.slug}` : null,
            title,
          };
        })}
      />
    );
  },
  /* eslint-disable-next-line react/prop-types */
  articles_carousel: ({
    categories,
    tags,
    skip,
    take,
    slidesPerPage,
    infinite,
    autoplay,
    articlesType,
    sorting,
    user,
    isPersonalizationEnabled,
    initialArticles,
    CMSSpacing,
    displayBackground,
    displayBorder,
    disableBannerRounding,
    locale,
    routingMap,
    heading,
  }) => {
    /* eslint-disable-next-line react/prop-types */
    const categoriesArray = categories ? mapDatoResponseToArticleCategoriesArray(categories) : [];
    const tagsArray = tags ? mapDatoResponseToArticleTagsArray(tags) : [];

    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLink heading={heading} locale={locale} routingMap={routingMap}>
          <ArticlesCarousel
            categories={categoriesArray}
            tags={tagsArray}
            articlesPerPage={+slidesPerPage}
            infinite={infinite}
            autoPlay={autoplay}
            take={take}
            skip={skip}
            articlesType={articlesType}
            sortingByPersonalizationTags={sorting}
            user={user}
            isPersonalizationEnabled={isPersonalizationEnabled}
            initialArticles={initialArticles}
            disableBannerRounding={disableBannerRounding}
          />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },
  /* eslint-disable-next-line react/prop-types */
  banners_carousel: ({ infinite, autoplay, bannersCarouselSet }) => (
    <BannersCarousel
      infinite={infinite}
      autoPlay={autoplay}
      /* eslint-disable-next-line react/prop-types */
      banners={bannersCarouselSet.bannersCarouselSetContent.map((entry) => {
        const { image, page, title } = entry;

        return {
          imgUrl: image.url,
          slug: page?.slug ? `/${page.slug}` : null,
          layout: page?.layout ? `/${page.layout}` : null,
          title: title || '',
        };
      })}
    />
  ),
  /* eslint-disable-next-line react/prop-types */
  images_carousel: ({
    infinite,
    autoplay,
    bannersCarousel,
    imagesQuality,
    slidesPerViewOnDesktop,
    showArrows,
    showDots,
    maxWidth,
    alignBanner,
    CMSSpacing,
    displayBackground,
    displayBorder,
    disableBannerRounding,
    locale,
    routingMap,
    heading,
  }) => {
    if (!bannersCarousel) return null;
    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLink locale={locale} routingMap={routingMap} heading={heading}>
          <BannersSlider
            infinite={infinite}
            autoPlay={autoplay}
            slidesPerViewOnDesktop={slidesPerViewOnDesktop}
            showArrows={showArrows}
            showDots={showDots}
            maxWidth={maxWidth}
            disableBannerRounding={disableBannerRounding}
            alignBanner={alignBanner}
            /* eslint-disable-next-line react/prop-types */
            banners={bannersCarousel.map((entry) => {
              const { filename, customData, height, url, width, alt, title } = entry;

              return {
                imgUrl: url,
                title: alt || title || filename,
                height,
                width,
                customData,
                quality: imagesQuality,
              };
            })}
          />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },
  /* eslint-disable-next-line react/prop-types */
  images_gallery: ({
    images,
    showArrows,
    showDots,
    galleryItemSize,
    horizontalLocation,
    CMSSpacing,
    displayBackground,
    displayBorder,
    routingMap,
    locale,
    heading,
  }) => {
    if (!images) return null;

    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLink heading={heading} routingMap={routingMap} locale={locale}>
          <ImagesGallery
            showArrows={showArrows}
            showDots={showDots}
            galleryItemSize={galleryItemSize}
            horizontalLocation={horizontalLocation}
            items={images.map((entry) => {
              const { filename, height, url, width, mimeType, alt, title } = entry;

              return {
                imageurl: url,
                title: alt || title || filename || '',
                height,
                width,
                type: mimeType,
              };
            })}
          />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },

  // ),
  /* eslint-disable-next-line react/prop-types */
  grid_navigation: ({ gridNavigationSet }) => (
    <GridNavigation
      /* eslint-disable-next-line react/prop-types */
      items={gridNavigationSet.gridNavigationSetContent.map((entry) => {
        const { firstLine, secondLine, image, page } = entry;

        return {
          imgUrl: image.url,
          title: firstLine,
          description: secondLine,
          url: page?.slug ? `/${page.slug}` : null,
          layout: page?.layout ? `/${page.layout}` : null,
        };
      })}
    />
  ),
  producers_list: ({
    logosPerPage,

    smallLogos,
    greyscaleEffect,
    autoplay,
    infinite,
    producers,
    coreRoutesInfo,
    locale,
    CMSSpacing,
    displayBackground,
    displayBorder,
    routingMap,
    heading,
  }) => {
    const producersString = mapDatoResponseToProducersQueryString(producers);
    const logoSize = smallLogos ? 90 : 109;
    if (!producersString) return null;

    return (
      <ProducersCarouselWrapperWithBorder
        displayBackground={displayBackground}
        displayBorder={displayBorder}
        CMSSpacing={CMSSpacing}
      >
        <WrapperWithHeadingAndLink locale={locale} heading={heading} routingMap={routingMap}>
          <ProducersList
            maxSlidesPerPage={logosPerPage}
            logoSize={logoSize}
            greyscaleEffect={greyscaleEffect}
            autoPlay={autoplay}
            producers={producersString}
            infinite={infinite}
            brandPageSlug={coreRoutesInfo.brandPage.name[locale]}
            brandPageLayout={coreRoutesInfo.brandPage.layout}
          />
        </WrapperWithHeadingAndLink>
      </ProducersCarouselWrapperWithBorder>
    );
  },
  /* eslint-disable-next-line react/prop-types */
  articles_list: ({
    title,

    categories,
    tags,
    take,
    skip,
    highlight,
    small,
    size,
    paginationAlign,
    showBrandTags,
    showProductCategoriesTags,
    showReadMoreButton,
    showOtherTags,
    variant,
    hidePagination,
    showPublicationDate,
    articlesType,
    sorting,
    locale,
    user,
    isPersonalizationEnabled,
    initialArticles,
    sideContentPageKeysValues,
    where,
    CMSSpacing,
    displayBackground,
    displayBorder,
    heading,
    routingMap,
  }) => {
    /* eslint-disable-next-line react/prop-types */
    const categoriesArray = categories ? mapDatoResponseToArticleCategoriesArray(categories) : [];
    const tagsArray = tags ? mapDatoResponseToArticleTagsArray(tags) : [];

    return (
      <S.ArticlesWrapper displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLink heading={heading} routingMap={routingMap} locale={locale}>
          <ArticlesList
            user={user}
            title={title}
            showTitle={false}
            categories={categoriesArray}
            sortingByPersonalizationTags={sorting}
            take={take}
            skip={skip}
            tags={tagsArray}
            showBrandTags={showBrandTags}
            showProductCategoriesTags={showProductCategoriesTags}
            showReadMoreButton={showReadMoreButton}
            showOtherTags={showOtherTags}
            isHighlighted={!!highlight}
            isSmall={!!small}
            hidePagination={hidePagination}
            paginationAlign={paginationAlign}
            size={articleListSizes[size]}
            variant={articlesListVariants[variant]}
            articlesType={articlesType}
            showPublicationDate={showPublicationDate}
            locale={locale}
            isPersonalizationEnabled={isPersonalizationEnabled}
            initialArticles={initialArticles}
            sideContentPageKeysValues={sideContentPageKeysValues}
            where={where}
          />
        </WrapperWithHeadingAndLink>
      </S.ArticlesWrapper>
    );
  },
  articles_list_aside: ({
    title,

    categories,
    tags,
    take,
    skip,
    highlight,
    paginationAlign,
    variant,
    hidePagination,
    articlesType,
    sorting,
    showPublicationDate,
    locale,
    isPersonalizationEnabled,
    user,
    initialArticles,
    sideContentPageKeysValues,
    CMSSpacing,
    displayBackground,
    displayBorder,
    heading,
    routingMap,
  }) => {
    const categoriesArray = categories ? mapDatoResponseToArticleCategoriesArray(categories) : [];
    const tagsArray = tags ? mapDatoResponseToArticleTagsArray(tags) : [];

    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLinkAside heading={heading} routingMap={routingMap} locale={locale}>
          <ArticlesList
            user={user}
            title={title}
            showTitle={false}
            categories={categoriesArray}
            sortingByPersonalizationTags={sorting}
            take={take}
            skip={skip}
            tags={tagsArray}
            isHighlighted={!!highlight}
            hidePagination={hidePagination}
            showPublicationDate={showPublicationDate}
            paginationAlign={paginationAlign}
            size={articleListSizes.aside}
            variant={articlesListVariants[variant]}
            articlesType={articlesType}
            isPersonalizationEnabled={isPersonalizationEnabled}
            locale={locale}
            initialArticles={initialArticles}
            sideContentPageKeysValues={sideContentPageKeysValues}
            isArticleListAside
          />
        </WrapperWithHeadingAndLinkAside>
      </WrapperWithBorder>
    );
  },
  /* eslint-disable react/prop-types */
  branch_info: ({ branch, CMSSpacing, displayBackground, displayBorder, heading, locale, routingMap }) => {
    const {
      title,
      subtitle,
      showExpressLogo,
      phoneNumber,
      emailAddress,
      addressLine1,
      addressLine2,
      openingHours,
      descriptionTitle,
      description,
      gmapsIframeUrl,
      gmapsDesignateDirections,
      city,
      postCode,
      iconBasket,
      iconProducts,
      iconConsultancy,
      iconCafe,
      iconEDeliveries,
      iconCardPayment,
      iconCredit,
    } = branch;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const router = useRouter();

    const iconsAndItemsTuplesArray = [
      ['cart', iconBasket],
      ['thousandProducts', iconProducts],
      ['technicalConsultancy', iconConsultancy],
      ['coffee', iconCafe],
      ['receiptOrders', iconEDeliveries],
      ['cardPayment', iconCardPayment],
      ['tradeCredit', iconCredit],
    ];

    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLink locale={locale} routingMap={routingMap} heading={heading}>
          <BranchInfo
            heading={title}
            subHeading={subtitle}
            showLogo={showExpressLogo}
            phoneNumber={phoneNumber}
            emailAddress={emailAddress}
            address={`${addressLine1},\n ${addressLine2}`}
            openingHours={openingHours}
            descriptionTitle={descriptionTitle}
            description={description}
            gmapsDesignateDirections={gmapsDesignateDirections}
            mapUrl={gmapsIframeUrl}
            items={iconsAndItemsTuplesArray.map(([iconName, iconDesc]) => ({
              icon: iconName,
              title: iconDesc || '',
              isVisible: !!iconDesc,
            }))}
          />
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: `// <![CDATA[
              {
                "@context": "http://schema.org",
                "@type": "LocalBusiness",
                "name": "${title} - ${subtitle}",
                "url": " https://onninen.pl${router.asPath}", 
                "image": "https://onninen.pl/static/svg/logo.svg", 
                "hasMap": " ${gmapsDesignateDirections}", 
                "telephone": " +48 ${phoneNumber}",  
                "email": "${emailAddress}",
                "address": {
                  "@type": "PostalAddress",
                  "addressLocality": "${city}",
                  "streetAddress": "${addressLine1}",
                  "postalCode": "${postCode}",
                  "addressCountry": "PL"
                  },
                  "openingHoursSpecification":
                  [
                    {
                      "@type": "OpeningHoursSpecification",
                      "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                        ], 
                        "opens": "7:00",
                        "closes": "16:00"
                        }
                        ]
                        }
                        // ]]>`,
            }}
          />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },
  /* eslint-disable react/prop-types */
  column_navigation: ({
    headingElement,
    navTitle,
    customNavigation,
    addSingOutButton,
    locale,
    coreRoutesInfo,
    user,
    CMSSpacing,
  }) => {
    const { set } = customNavigation;
    const noAccessNameList = (user?.accessList || []).map(({ name }) => name);
    const cleanNavSetList = filterNavigationSet(set, noAccessNameList);

    return (
      <ColumnNavigation
        headingComponent={headingElement}
        navTitle={navTitle}
        pages={cleanNavSetList}
        shouldAddSingOutButton={addSingOutButton}
        homePageLayout={coreRoutesInfo.homePage.layout}
        homePageSlug={coreRoutesInfo.homePage.name[locale]}
        CMSSpacing={CMSSpacing}
        user={user}
      />
    );
  },
  brand_showcase: ({ name, description, logo }) => <BrandShowcase title={name} body={description} img={logo} />,
  short_user_summary: ({
    coreRoutesInfo,
    locale,
    showSection,
    sideContentPageKeysValues,
    CMSSpacing,
    displayBackground,
    displayBorder,
    routingMap,
    heading,
    user,
  }) => {
    if (!user) return null;
    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLink heading={heading} locale={locale} routingMap={routingMap}>
          <ShortUserSummary
            coreRoutesInfo={coreRoutesInfo}
            locale={locale}
            showSection={showSection}
            sideContentPageKeysValues={sideContentPageKeysValues}
          />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },
  offer_carousel_vertical: ({
    indexes,
    query,
    shuffle,
    hideOnntopMarkers,
    coreRoutesInfo,
    locale,
    limit,
    user,
    where,
    CMSSpacing,
    displayBackground,
    displayBorder,
    routingMap,
    heading,
  }) => {
    return query?.length || indexes?.length ? (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLinkAside locale={locale} heading={heading} routingMap={routingMap}>
          <CarouselOfProductsFromQuery
            query={indexes?.length ? formatIndexesQuery(indexes) : removeUnnecessaryQueryStrings(query)}
            shuffle={shuffle}
            productPageSlug={coreRoutesInfo.productPage.name[locale]}
            productPageLayout={coreRoutesInfo.productPage.layout}
            onnTopHomePageLayout={coreRoutesInfo.onntopHomepage.layout}
            onnTopHomePageSlug={coreRoutesInfo.onntopHomepage.name[locale]}
            userName={user?.user?.name || null}
            userEmail={user?.user?.email || null}
            limit={limit}
            vertical
            hideOnnTop={hideOnntopMarkers}
            homePageForUserLayout={coreRoutesInfo.userB2bHomePage.layout}
            where={where}
          />
        </WrapperWithHeadingAndLinkAside>
      </WrapperWithBorder>
    ) : null;
  },
  small_recommendation_list: ({
    title,
    indexes,
    query,
    columnsOnDesktop,
    row,
    shuffle,
    locale,
    coreRoutesInfo,
    CMSSpacing,
    displayBackground,
    displayBorder,
    heading,

    routingMap,
  }) => {
    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLink heading={heading} routingMap={routingMap} locale={locale}>
          <RecommendationHorizontalList
            title={title}
            query={indexes?.length ? formatIndexesQuery(indexes) : removeUnnecessaryQueryStrings(query)}
            columnsOnDesktop={columnsOnDesktop}
            rows={row || undefined}
            shuffle={shuffle}
            productPageSlug={coreRoutesInfo.productPage.name[locale]}
            productPageLayout={coreRoutesInfo.productPage.layout}
          />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },
  marketing_agree: ({
    typesOfAgree,
    CMSSpacing,
    displayBackground,
    displayBorder,
    locale,
    routingMap,
    heading,
    user,
  }) => {
    if (!user) return null;
    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLink heading={heading} locale={locale} routingMap={routingMap}>
          <MarketingAgrees typesOfAgree={typesOfAgree} />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },
  einvoice: ({ CMSSpacing, displayBackground, displayBorder, locale, heading, routingMap, user }) => {
    if (!user) return null;
    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLink locale={locale} heading={heading} routingMap={routingMap}>
          <EInvoice />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },
  regulations_acceptance: ({ CMSSpacing }) => {
    return <RegulationsAcceptance CMSSpacing={CMSSpacing} />;
  },
  promotion: ({
    identifier,
    isPromotion,
    CMSSpacing,
    displayBackground,
    displayBorder,
    locale,
    routingMap,
    heading,
  }) => {
    const DEFAULT_PROMOTION_WRAPPER_MARGIN = 0;

    const calculatedCMSSpacing = {
      'margin-top': `${CMSSpacing['margin-top'] || DEFAULT_PROMOTION_WRAPPER_MARGIN}px`,
      'margin-bottom': `${CMSSpacing['margin-bottom'] || DEFAULT_PROMOTION_WRAPPER_MARGIN}px`,
    };

    return (
      <WrapperWithBorder
        displayBackground={displayBackground}
        displayBorder={displayBorder}
        CMSSpacing={calculatedCMSSpacing}
      >
        <WrapperWithHeadingAndLink locale={locale} routingMap={routingMap} heading={heading}>
          <Promotion id={identifier} isPromotion={isPromotion} />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },

  images_carousel_personalized: ({
    infinite,
    autoplay,
    personalizedImages,
    imagesQuality,
    slidesPerViewOnDesktop,
    showArrows,
    showDots,
    maxWidth,
    alignBanner,
    CMSSpacing,
    displayBackground,
    displayBorder,
    disableBannerRounding,
    locale,
    routingMap,
    heading,
  }) => {
    const noImages = personalizedImages.length === 0;
    if (noImages) {
      return null;
    }

    return (
      <WrapperWithBorder displayBackground={displayBackground} displayBorder={displayBorder} CMSSpacing={CMSSpacing}>
        <WrapperWithHeadingAndLink locale={locale} heading={heading} routingMap={routingMap}>
          <BannersSlider
            infinite={infinite}
            autoPlay={autoplay}
            slidesPerViewOnDesktop={slidesPerViewOnDesktop}
            showArrows={showArrows}
            showDots={showDots}
            maxWidth={maxWidth}
            alignBanner={alignBanner}
            disableBannerRounding={disableBannerRounding}
            /* eslint-disable-next-line react/prop-types */
            banners={personalizedImages
              .filter(({ image }) => image !== null)
              .map((entry) => {
                const { filename, customData, height, url, width, alt, title } = entry.image;

                return {
                  imgUrl: url,
                  title: alt || title || filename,
                  height,
                  width,
                  customData,
                  quality: imagesQuality,
                };
              })}
          />
        </WrapperWithHeadingAndLink>
      </WrapperWithBorder>
    );
  },
};

const defaultProps = {
  innerRef: null,
  isSticky: false,
};

export const Content = ({
  body,
  coreRoutesInfo,
  locale,
  user,
  innerRef,
  isSticky,
  sideContentPageKeysValues,
  where,
  routingMap,

  ...restProps
} = defaultProps) => {
  if (process.env.nodeEnv !== 'production' && !user) {
    // eslint-disable-next-line no-console
    console.warn('<Content /> has not got user object');
  }

  return (
    <S.Content ref={innerRef} shouldBeSticky={isSticky} {...restProps}>
      {body.map((elementSet, elementIdx) => {
        const elementName = elementSet._modelApiKey;
        const elementId = elementSet.id || '';
        const key = `${elementName}-${elementIdx}-${elementId}`;
        const handler = elementHandlers[elementName];

        const CMSSpacing = {
          'margin-top': elementSet.marginTop || 0,
          'margin-bottom': elementSet.marginBottom || 0,
        };

        if (!handler) {
          return null;
        }

        return (
          <Fragment key={key}>
            {handler({
              ...elementSet,
              coreRoutesInfo,
              locale,
              user,
              sideContentPageKeysValues,
              where,
              CMSSpacing,
              routingMap,
            })}
          </Fragment>
        );
      })}
    </S.Content>
  );
};

Content.defaultProps = defaultProps;
