import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import * as S from './styles';
import transformUrlToNextLink from 'src/utils/transformUrlToNextLink';
import { breakpointsKeys } from 'src/enums/breakpoints';
import { defaultLanguage } from 'config/locales';

export const WrapperWithHeadingAndLinkAside = ({ children, heading, routingMap, locale }) => {
  const currentBreakpoint = useBreakpointDetector();

  const isMobile = currentBreakpoint === breakpointsKeys.MOBILE;
  const isTablet = currentBreakpoint === breakpointsKeys.TABLET;
  const renderHeading = !!heading;
  const routesMap = new Map(routingMap?.routesMap);
  const slugsMap = new Map(routingMap?.slugsMap);
  const addLangToLink = () => {
    if (!heading?.link) return null;
    if (locale !== defaultLanguage) {
      return `${locale}${heading.link}`;
    }
    return heading.link;
  };

  const nextLinkData = transformUrlToNextLink({ href: addLangToLink(), routesMap, slugsMap });
  const CMSSpacing = {
    'margin-top': heading?.marginTop || 0,
    'margin-bottom': heading?.marginBottom || 0,
  };
  return (
    <>
      {(heading?.firstLine || !isMobile) && renderHeading && (
        <S.HeadingForHeadingWithLinkWrapper
          variant="quaternary"
          component={heading?.tag}
          isLinkRendered={!!nextLinkData}
          displayBorder={false}
          displayBackground={false}
          CMSSpacing={CMSSpacing}
          isBig={heading?.isBig}
        >
          {heading?.firstLine}
          {!!nextLinkData && isTablet && (
            <S.ShowMoreHeadingLinkCarouselUpperMobile nextLinkData={nextLinkData} isBig={heading?.isBig} />
          )}
        </S.HeadingForHeadingWithLinkWrapper>
      )}
      {children}
      {!!nextLinkData && !isTablet && <S.ShowMoreHeadingLinkCarousel nextLinkData={nextLinkData} />}
    </>
  );
};
