import styled from 'styled-components';

export const rotateIconDependsOnDirection = (direction) => ({ up: '270', down: '90', left: '180' }[direction] ?? '0');

const SVG = styled.svg`
  cursor: pointer;

  ${({ rotate }) => rotate && `rotate: ${rotate}deg`};
`;

export const ArrowInCircle = ({ className, direction = 'right', ...rest }) => {
  const rotate = rotateIconDependsOnDirection(direction);

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      rotate={rotate}
      className={className}
      {...rest}
    >
      <path
        /* eslint-disable-next-line */
        d="M7 12.6406C9.89844 12.6406 12.25 10.2891 12.25 7.39062C12.25 4.49219 9.89844 2.14063 7 2.14063C4.10156 2.14063 1.75 4.49219 1.75 7.39063C1.75 10.2891 4.10156 12.6406 7 12.6406Z"
        fill="white"
        stroke="#00857E"
        /* eslint-disable-next-line */
        strokeMiterlimit="10"
      />
      <path
        d="M5.90625 4.76563L8.53125 7.39062L5.90625 10.0156"
        stroke="#00857E"
        /* eslint-disable-next-line */
        strokeLinecap="round"
        /* eslint-disable-next-line */
        strokeLinejoin="round"
      />
    </SVG>
  );
};
